<template>
  <div class="toast-container" aria-live="polite" aria-atomic="true">
    <Toast v-for="toast in toasts" :key="toast.id" :toast="toast" />
  </div>
</template>

<script>
import Toast from "@/components/Toast";
import store from "@/store";
import { computed } from "vue";
export default {
  name: "ToastService",
  components: {
    Toast,
  },
  setup() {
    const toasts = computed(() => {
      return store.getters["toasts/getStack"];
    });

    return {
      toasts,
    };
  },
};
</script>

<style lang="postcss">
.toast-container {
  position: fixed;
  height: 0;
  overflow: visible;
  top: 1rem;
  z-index: 2060;
  left: 50%;
  transform: translateX(-50%);
}
</style>
