const loading = {
  namespaced: true,
  state: {
    global: false,
  },
  mutations: {
    SET_GLOBAL(state, payload) {
      state.global = payload;
    },
  },
  actions: {
    setGlobal({ commit, state }, payload) {
      commit("SET_GLOBAL", payload);
    },
  },
  getters: {
    getGlobal: (state) => state.global,
  },
};

export default loading;
