<template>
  <router-view v-if="!loading" />
  <toast-service />
</template>

<script>
import { onMounted, ref } from "vue";
import { setErrorResponseListener } from "@/request";
import store from "@/store";
import router from "./router";
import constants from "./constants";
import ToastService from "./components/ToastService.vue";

export default {
  name: "App",
  components: { ToastService },
  setup() {
    const loading = ref(true);

    const logoutUnauthorized = (error) => {
      console.log("logoutUnauthorized from App.vue", { error });
      if (store.getters["auth/isLoggedIn"] && error.response?.status === 403) {
        router.push({ name: constants.LOGOUT_ROUTE });
      }
    };

    onMounted(() => {
      setErrorResponseListener(logoutUnauthorized);
      loading.value = false;
    });

    return {
      loading,
    };
  },
};
</script>

<style></style>
