import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { setBaseUrl } from "./request";
import VueGtag from "vue-gtag-next";
import VueRecaptcha from "vue3-recaptcha-v2";
import "@/styles/index.css";

const app = createApp(App);

if (process.env.VUE_APP_API_URL) {
  setBaseUrl(process.env.VUE_APP_API_URL);
}

if (process.env.VUE_APP_GTAG_ID) {
  app.use(VueGtag, {
    property: {
      id: process.env.VUE_APP_GTAG_ID,
    },
  });
}

if (process.env.VUE_APP_RECAPTCHA_KEY) {
  app.use(VueRecaptcha, {
    siteKey: process.env.VUE_APP_RECAPTCHA_KEY,
  });
}

app.use(store).use(router).mount("#app");
