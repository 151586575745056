import { set, read, clearAll } from "@/cache";
import { make } from "@/request";
import { get as lsGet, remove as lsRemove, set as lsSet } from "local-storage";

const idFromLs = lsGet("hashku_user_id");

const auth = {
  namespaced: true,
  state: {
    user: null,
    user_id: idFromLs,
  },
  mutations: {
    SET_USER(state, payload) {
      lsSet("hashku_user_id", payload.id);
      state.user_id = payload?.id;
      state.user = payload;
    },
    CLEAR_USER(state) {
      state.user_id = null;
      state.user = null;
      lsRemove("hashku_user_id");
    },
  },
  actions: {
    async login({ commit, dispatch }, data) {
      const csrf = await make({ name: "getCsrfCookie" });

      const loginAttempt = await make({
        name: "login",
        data,
      });

      const user = await dispatch("getUser");
      commit("SET_USER", user);

      return user;
    },
    async getUser({ commit, dispatch }) {
      const user = await make({
        name: "getUser",
      });

      if (!user) {
        console.log("requested user, but data missing", { user });
        dispatch("logout");
        return;
      }

      console.log("got user", { user });
      commit("SET_USER", user);

      return user;
    },
    async logout({ commit, dispatch }) {
      const logout = await make({
        name: "logout",
      });

      commit("CLEAR_USER");

      return;
    },
  },
  getters: {
    isLoggedIn(state) {
      return !!state?.user_id;
    },
    getTeamRole: (state, getters, rootState) => (teamId) => {
      return state.user?.teams?.find((b) => b.id == teamId)?.pivot?.role ?? null;
    },
  },
};

export default auth;
