import RequestDefinition from "../classes/RequestDefinition";

export const getCsrfCookie = new RequestDefinition({
  path: "sanctum/csrf-cookie",
  method: "get",
});

export const login = new RequestDefinition({
  path: "login",
  method: "post",
});

export const logout = new RequestDefinition({
  path: "logout",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.successes ?? null;
  },
});

export const getUser = new RequestDefinition({
  path: "user",
  method: "get",
  transformResponse: (response) => {
    return response.data?.content?.user ?? null;
  },
});

export const getTeam = new RequestDefinition({
  path: "team",
  method: "get",
  transformResponse: (response) => {
    return response.data?.content?.team ?? null;
  },
});

export const getCollection = new RequestDefinition({
  path: "collection",
  method: "get",
  transformResponse: (response) => {
    return response.data?.content?.collection ?? null;
  },
});

export const createCollection = new RequestDefinition({
  path: "collection",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.collection ?? null;
  },
});

export const uploadFile = new RequestDefinition({
  path: "collection/upload", // ?company_id=1&id=1"
  method: "post",
  transformRequest: (data) => {
    const formData = new FormData();
    formData.append("file", data.file);
    if (data.name) {
      formData.append("name", data.name);
    }
    if (data.id) {
      formData.append("id", data.id);
    }
    if (data.attributesn) {
      formData.append("attributesn", data.attributesn);
    }
    formData.append("collection_slug", data.collection_slug);
    formData.append("team_slug", data.team_slug);
    return formData;
  },
  headers: {
    "Content-Type": "multipart/form-data",
  },
  transformResponse: (response) => {
    return response.data?.content?.image_file ?? null;
  },
});

export const deleteFile = new RequestDefinition({
  path: "collection/file",
  method: "delete",
  transformResponse: (response) => {
    return response.data?.content?.successes ?? null;
  },
});

export const updateFile = new RequestDefinition({
  path: "collection/file",
  method: "put",
  transformResponse: (response) => {
    return response.data?.content?.image_file ?? null;
  },
});

export const toggleFileFlag = new RequestDefinition({
  path: "collection/file/flag",
  method: "put",
  transformResponse: (response) => {
    return response.data?.content?.image_file ?? null;
  },
});

export const createBase = new RequestDefinition({
  path: "collection/base",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.base ?? null;
  },
});

export const updateBase = new RequestDefinition({
  path: "collection/base",
  method: "put",
  transformResponse: (response) => {
    return response.data?.content?.base ?? null;
  },
});

export const getBaseOutputs = new RequestDefinition({
  path: "collection/base/outputs",
  method: "post",
  transformResponse: (response) => {
    return {
      outputs: response.data?.content?.outputs ?? [],
      current_page: response.data?.content?.current_page,
      total_pages: response.data?.content?.total_pages,
      sort_by: response.data?.content?.sort_by,
      sort_direction: response.data?.content?.sort_direction,
    };
  },
});

export const updateBaseOutput = new RequestDefinition({
  path: "collection/base/outputs",
  method: "put",
  transformResponse: (response) => {
    return response.data?.content?.output ?? null;
  },
});

export const deleteBaseOutput = new RequestDefinition({
  path: "collection/base/output",
  method: "delete",
  transformResponse: (response) => {
    return response.data?.content?.successes ?? null;
  },
});

export const uploadBaseOutput = new RequestDefinition({
  path: "collection/base/outputs/upload", // ?company_id=1&id=1"
  method: "post",
  transformRequest: (data) => {
    const formData = new FormData();
    formData.append("file", data.file);
    if (data.name) {
      formData.append("name", data.name);
    }
    formData.append("base_id", data.base_id);
    formData.append("collection_slug", data.collection_slug);
    formData.append("team_slug", data.team_slug);
    return formData;
  },
  headers: {
    "Content-Type": "multipart/form-data",
  },
  transformResponse: (response) => {
    return response.data?.content?.output ?? null;
  },
});

export const getImageFiles = new RequestDefinition({
  path: "collection/files",
  method: "get",
  transformResponse: (response) => {
    return {
      image_files: response.data?.content?.image_files ?? [],
      current_page: response.data?.content?.current_page,
      total_pages: response.data?.content?.total_pages,
      sort_by: response.data?.content?.sort_by,
      sort_direction: response.data?.content?.sort_direction,
    };
  },
});

export const getAllImageFiles = new RequestDefinition({
  path: "collection/files/all",
  method: "get",
  transformResponse: (response) => {
    return response.data?.content?.image_files ?? null;
  },
});

export const getBaseFiles = new RequestDefinition({
  path: "collection/base/files",
  method: "get",
  transformResponse: (response) => {
    return response.data?.content?.base ?? null;
  },
});

export const createBaseFile = new RequestDefinition({
  path: "collection/base/file",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.file ?? null;
  },
});

export const createBaseFolder = new RequestDefinition({
  path: "collection/base/folder",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.folder ?? null;
  },
});

export const deleteBaseFile = new RequestDefinition({
  path: "collection/base/file",
  method: "delete",
  transformResponse: (response) => {
    return response.data?.content?.successes ?? null;
  },
});

export const deleteBaseFolder = new RequestDefinition({
  path: "collection/base/folder",
  method: "delete",
  transformResponse: (response) => {
    return response.data?.content?.successes ?? null;
  },
});

export const createBaseSet = new RequestDefinition({
  path: "collection/base/set",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.set ?? null;
  },
});

export const deleteBaseSet = new RequestDefinition({
  path: "collection/base/set",
  method: "delete",
  transformResponse: (response) => {
    return response.data?.content?.successes ?? null;
  },
});

export const generateSetOutput = new RequestDefinition({
  path: "collection/base/set/output",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.successes ?? null;
  },
});

export const generateAllSetsOutput = new RequestDefinition({
  path: "collection/base/set/outputs",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.successes ?? null;
  },
});

export const deleteAllBaseSets = new RequestDefinition({
  path: "collection/base/sets",
  method: "delete",
  transformResponse: (response) => {
    return response.data?.content?.successes ?? null;
  },
});

export const keepAllBaseSets = new RequestDefinition({
  path: "collection/base/sets",
  method: "put",
  transformResponse: (response) => {
    return response.data?.content?.successes ?? null;
  },
});

export const generateBaseSets = new RequestDefinition({
  path: "collection/base/sets",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.sets ?? null;
  },
});

export const getBaseSets = new RequestDefinition({
  path: "collection/base/sets/search",
  method: "post",
  transformResponse: (response) => {
    return {
      sets: response.data?.content?.sets ?? [],
      current_page: response.data?.content?.current_page,
      total_pages: response.data?.content?.total_pages,
      sort_by: response.data?.content?.sort_by,
      sort_direction: response.data?.content?.sort_direction,
    };
  },
});

export const deleteDuplicateBaseSets = new RequestDefinition({
  path: "collection/base/set/duplicates",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.successes ?? null;
  },
});

export const getBaseSetsAttributes = new RequestDefinition({
  path: "collection/base/sets/attributes",
  method: "post",
  transformResponse: (response) => {
    return {
      attributesn: response.data?.content?.attributesn ?? [],
      keys: response.data?.content?.keys ?? [],
      properties: response.data?.content?.properties ?? [],
      total: response.data?.content?.total ?? [],
      count: response.data?.content?.count ?? [],
    };
  },
});

export const getBaseOutputsMetadata = new RequestDefinition({
  path: "collection/base/outputs/metadata",
  method: "post",
  transformResponse: (response) => {
    return {
      metadata: response.data?.content?.metadata ?? [],
      keys: response.data?.content?.keys ?? [],
      properties: response.data?.content?.properties ?? [],
      total: response.data?.content?.total ?? [],
      count: response.data?.content?.count ?? [],
    };
  },
});

export const getCollectionSetsExport = new RequestDefinition({
  path: "collection/base/sets/export-all",
  method: "get",
  transformResponse: (response) => {
    console.log(response.headers);
    const contentDisposition = response.headers?.["content-disposition"];
    const filename =
      contentDisposition.split(";")?.[1]?.split("=")?.[1] ?? "hashku-all-sets-export.csv";
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename); //or any other extension
    document.body.appendChild(link);
    link.click();
    return response.data?.content ?? null;
  },
});

export const getBaseSetsExport = new RequestDefinition({
  path: "collection/base/sets/export",
  method: "get",
  transformResponse: (response) => {
    console.log(response.headers);
    const contentDisposition = response.headers?.["content-disposition"];
    const filename =
      contentDisposition.split(";")?.[1]?.split("=")?.[1] ?? "hashku-sets-export.csv";
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename); //or any other extension
    document.body.appendChild(link);
    link.click();
    return response.data?.content ?? null;
  },
});

export const toggleBaseSetSave = new RequestDefinition({
  path: "collection/base/set",
  method: "put",
  transformResponse: (response) => {
    return response.data?.content?.set ?? null;
  },
});

export const getBaseOutputsExport = new RequestDefinition({
  path: "collection/base/outputs/export",
  method: "get",
  transformResponse: (response) => {
    console.log(response.headers);
    const contentDisposition = response.headers?.["content-disposition"];
    const filename =
      contentDisposition.split(";")?.[1]?.split("=")?.[1] ?? "hashku-outputs-export.csv";
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename); //or any other extension
    document.body.appendChild(link);
    link.click();
    return response.data?.content ?? null;
  },
});

export const getCollectionOutputs = new RequestDefinition({
  path: "collection/outputs",
  method: "post",
  transformResponse: (response) => {
    return {
      outputs: response.data?.content?.outputs ?? [],
      current_page: response.data?.content?.current_page,
      total_pages: response.data?.content?.total_pages,
      sort_by: response.data?.content?.sort_by,
      sort_direction: response.data?.content?.sort_direction,
    };
  },
});

export const getCollectionOutputsMetadata = new RequestDefinition({
  path: "collection/outputs/metadata",
  method: "post",
  transformResponse: (response) => {
    return {
      metadata: response.data?.content?.metadata ?? [],
      keys: response.data?.content?.keys ?? [],
      properties: response.data?.content?.properties ?? [],
      total: response.data?.content?.total ?? [],
      count: response.data?.content?.count ?? [],
    };
  },
});
