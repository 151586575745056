import auth from "./auth";
import toasts from "./toasts";
import loading from "./loading";
import team from "./team";

export default {
  auth,
  toasts,
  loading,
  team,
};
