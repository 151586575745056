import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import constants from "@/constants";
import { trackRouter } from "vue-gtag-next";

const routes = [
  {
    path: "/logout",
    name: "Logout",
    component: () => import(/* webpackChunkName: "auth" */ "../pages/Logout.vue"),
    props: (route) => ({
      redirect: route.query.redirect,
      query: route.query.query,
      reason: route.query.reason,
    }),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import(/* webpackChunkName: "auth" */ "../pages/Login.vue"),
    meta: {
      authGuard: true,
    },
    props: (route) => ({
      redirect: route.query.redirect,
      query: route.query.query,
      reason: route.query.reason,
    }),
  },
  {
    path: "/",
    component: () => import(/* webpackChunkName: "home" */ "../pages/Dashboard.vue"),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "",
        name: "Home",
        component: () => import(/* webpackChunkName: "home" */ "../pages/Dashboard/Home.vue"),
      },
      {
        path: "team/:team_slug",
        component: () => import(/* webpackChunkName: "home" */ "../pages/Dashboard/Team.vue"),
        props: (route) => ({
          team_slug: route.params.team_slug,
        }),
        children: [
          {
            path: "",
            name: "Team-Home",
            component: () =>
              import(/* webpackChunkName: "home" */ "../pages/Dashboard/Team/Home.vue"),
          },
          {
            path: "collection/:collection_slug",
            component: () =>
              import(/* webpackChunkName: "home" */ "../pages/Dashboard/Team/Collection.vue"),
            props: (route) => ({
              collection_slug: route.params.collection_slug,
            }),
            children: [
              {
                path: "",
                name: "Team-Collection-Home",
                component: () =>
                  import(
                    /* webpackChunkName: "home" */ "../pages/Dashboard/Team/Collection/Home.vue"
                  ),
              },
              {
                path: "outputs",
                name: "Team-Collection-Outputs",
                component: () =>
                  import(
                    /* webpackChunkName: "home" */ "../pages/Dashboard/Team/Collection/Outputs.vue"
                  ),
              },
              {
                path: "images",
                name: "Team-Collection-Images",
                component: () =>
                  import(
                    /* webpackChunkName: "home" */ "../pages/Dashboard/Team/Collection/Images.vue"
                  ),
              },
              {
                path: "base/:base_id",
                component: () =>
                  import(
                    /* webpackChunkName: "home" */ "../pages/Dashboard/Team/Collection/Base.vue"
                  ),
                props: (route) => ({
                  base_id: route.params.base_id,
                }),
                children: [
                  {
                    path: "",
                    name: "Team-Collection-Base-Home",
                    component: () =>
                      import(
                        /* webpackChunkName: "home" */ "../pages/Dashboard/Team/Collection/Base/Home.vue"
                      ),
                  },
                  {
                    path: "files",
                    name: "Team-Collection-Base-Files",
                    component: () =>
                      import(
                        /* webpackChunkName: "home" */ "../pages/Dashboard/Team/Collection/Base/Files.vue"
                      ),
                  },
                  {
                    path: "sets",
                    name: "Team-Collection-Base-Sets",
                    component: () =>
                      import(
                        /* webpackChunkName: "home" */ "../pages/Dashboard/Team/Collection/Base/Sets.vue"
                      ),
                  },
                  {
                    path: "outputs",
                    name: "Team-Collection-Base-Outputs",
                    component: () =>
                      import(
                        /* webpackChunkName: "home" */ "../pages/Dashboard/Team/Collection/Base/Outputs.vue"
                      ),
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory("/"),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { left: 0, top: 0, behavior: "smooth" };
  },
});

router.beforeEach((to, from, next) => {
  if (to.name === "Logout") {
    to.query = {
      redirect: from.path,
      ...to.query,
    };
    console.log({ to, from });
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters["auth/isLoggedIn"]) {
      // if already on login, routing to login can fail
      // handle that case by doing nothing

      console.log("user blocked from page", {
        to,
        from,
        user: store.state.auth.user,
        email: store.state.auth.user_email,
      });

      if (from.name === constants.LOGIN_ROUTE) {
        return;
      } else {
        next({
          name: constants.LOGIN_ROUTE,
          query: { ...to.query, redirect: to.path },
        });
      }
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.authGuard)) {
    if (store.getters["auth/isLoggedIn"]) {
      console.log("user already logged in");

      next({
        name: constants.HOME_ROUTE,
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

trackRouter(router);

export default router;
