import { make } from "@/request";
import { createBaseFile, uploadFile } from "@/request/definitions";

const team = {
  namespaced: true,
  state: {
    item: null,
    collection: null,
  },
  mutations: {
    SET_ITEM(state, payload) {
      state.item = payload;
    },
    SET_COLLECTION(state, payload) {
      state.collection = payload;
    },
  },
  actions: {
    async getTeam({ commit, dispatch }, slug) {
      const teamAttempt = await make({
        name: "getTeam",
        params: {
          team_slug: slug,
        },
      });

      commit("SET_ITEM", teamAttempt);

      return teamAttempt;
    },
    async getCollection({ commit, state }, slug) {
      const collection = await make({
        name: "getCollection",
        params: {
          team_slug: state.item.slug,
          collection_slug: slug,
        },
      });
      commit("SET_COLLECTION", collection);

      return collection;
    },
    async createCollection({ commit, state }, data) {
      const req = await make({
        name: "uploadFile",
        data: {
          ...data,
          team_slug: state.item.slug,
        },
      });

      return req;
    },
    async uploadFile({ state }, data) {
      const req = await make({
        name: "uploadFile",
        data: {
          ...data,
          team_slug: state.item.slug,
          collection_slug: state.collection.slug,
        },
      });

      return req;
    },
    async deleteFile({ state }, data) {
      const req = await make({
        name: "deleteFile",
        data: {
          ...data,
          team_slug: state.item.slug,
          collection_slug: state.collection.slug,
        },
      });

      return req;
    },
    async updateFile({ state }, data) {
      const req = await make({
        name: "updateFile",
        data: {
          ...data,
          team_slug: state.item.slug,
          collection_slug: state.collection.slug,
        },
      });

      return req;
    },
    async toggleFileFlag({ state }, data) {
      const req = await make({
        name: "toggleFileFlag",
        data: {
          ...data,
          team_slug: state.item.slug,
          collection_slug: state.collection.slug,
        },
      });

      return req;
    },
    async createBase({ state, dispatch }, data) {
      const req = await make({
        name: "createBase",
        data: {
          ...data,
          team_slug: state.item.slug,
          collection_slug: state.collection.slug,
        },
      });

      await dispatch("getCollection", state.collection.slug);

      return req;
    },
    async updateBase({ state, dispatch }, data) {
      const req = await make({
        name: "updateBase",
        data: {
          ...data,
          team_slug: state.item.slug,
          collection_slug: state.collection.slug,
        },
      });

      await dispatch("getCollection", state.collection.slug);

      return req;
    },
    async getBaseOutputs({ state }, data) {
      const req = await make({
        name: "getBaseOutputs",
        data: {
          ...data,
          team_slug: state.item.slug,
          collection_slug: state.collection.slug,
        },
      });

      return req;
    },
    async uploadBaseOutput({ state }, data) {
      const req = await make({
        name: "uploadBaseOutput",
        data: {
          ...data,
          team_slug: state.item.slug,
          collection_slug: state.collection.slug,
        },
      });

      return req;
    },
    async updateBaseOutput({ state }, data) {
      const req = await make({
        name: "updateBaseOutput",
        data: {
          ...data,
          team_slug: state.item.slug,
          collection_slug: state.collection.slug,
        },
      });

      return req;
    },
    async deleteBaseOutput({ state }, data) {
      const req = await make({
        name: "deleteBaseOutput",
        data: {
          ...data,
          team_slug: state.item.slug,
          collection_slug: state.collection.slug,
        },
      });

      return req;
    },
    async getImageFiles({ state }, data) {
      const req = await make({
        name: "getImageFiles",
        params: {
          ...data,
          team_slug: state.item.slug,
          collection_slug: state.collection.slug,
        },
      });

      return req;
    },
    async getAllImageFiles({ state }, data) {
      const req = await make({
        name: "getAllImageFiles",
        params: {
          ...data,
          team_slug: state.item.slug,
          collection_slug: state.collection.slug,
        },
      });

      return req;
    },
    async getBaseFiles({ state }, data) {
      const req = await make({
        name: "getBaseFiles",
        params: {
          ...data,
          team_slug: state.item.slug,
          collection_slug: state.collection.slug,
        },
      });

      return req;
    },
    async createBaseFile({ state }, data) {
      const req = await make({
        name: "createBaseFile",
        data: {
          ...data,
          team_slug: state.item.slug,
          collection_slug: state.collection.slug,
        },
      });

      return req;
    },
    async createBaseFolder({ state }, data) {
      const req = await make({
        name: "createBaseFolder",
        data: {
          ...data,
          team_slug: state.item.slug,
          collection_slug: state.collection.slug,
        },
      });

      return req;
    },
    async deleteBaseFile({ state }, data) {
      const req = await make({
        name: "deleteBaseFile",
        params: {
          ...data,
          team_slug: state.item.slug,
          collection_slug: state.collection.slug,
        },
      });

      return req;
    },
    async deleteBaseFolder({ state }, data) {
      const req = await make({
        name: "deleteBaseFolder",
        params: {
          ...data,
          team_slug: state.item.slug,
          collection_slug: state.collection.slug,
        },
      });

      return req;
    },
    async getBaseSets({ state }, data) {
      const req = await make({
        name: "getBaseSets",
        data: {
          ...data,
          team_slug: state.item.slug,
          collection_slug: state.collection.slug,
        },
      });
      return req;
    },
    async createBaseSet({ state }, data) {
      const req = await make({
        name: "createBaseSet",
        data: {
          ...data,
          team_slug: state.item.slug,
          collection_slug: state.collection.slug,
        },
      });
      return req;
    },
    async deleteBaseSet({ state }, data) {
      const req = await make({
        name: "deleteBaseSet",
        data: {
          ...data,
          team_slug: state.item.slug,
          collection_slug: state.collection.slug,
        },
      });
      return req;
    },
    async generateSetOutput({ state }, data) {
      const req = await make({
        name: "generateSetOutput",
        data: {
          ...data,
          team_slug: state.item.slug,
          collection_slug: state.collection.slug,
        },
      });
      return req;
    },
    async generateAllSetsOutput({ state }, data) {
      const req = await make({
        name: "generateAllSetsOutput",
        data: {
          ...data,
          team_slug: state.item.slug,
          collection_slug: state.collection.slug,
        },
      });
      return req;
    },
    async generateBaseSets({ state }, data) {
      const req = await make({
        name: "generateBaseSets",
        data: {
          ...data,
          team_slug: state.item.slug,
          collection_slug: state.collection.slug,
        },
      });
      return req;
    },
    async toggleBaseSetSave({ state }, data) {
      const req = await make({
        name: "toggleBaseSetSave",
        data: {
          ...data,
          team_slug: state.item.slug,
          collection_slug: state.collection.slug,
        },
      });
      return req;
    },
    async deleteAllBaseSets({ state }, data) {
      const req = await make({
        name: "deleteAllBaseSets",
        data: {
          ...data,
          team_slug: state.item.slug,
          collection_slug: state.collection.slug,
        },
      });
      return req;
    },
    async keepAllBaseSets({ state }, data) {
      const req = await make({
        name: "keepAllBaseSets",
        data: {
          ...data,
          team_slug: state.item.slug,
          collection_slug: state.collection.slug,
        },
      });
      return req;
    },
    async getBaseSetsAttributes({ state }, data) {
      const req = await make({
        name: "getBaseSetsAttributes",
        data: {
          ...data,
          team_slug: state.item.slug,
          collection_slug: state.collection.slug,
        },
      });
      return req;
    },
    async getBaseOutputsMetadata({ state }, data) {
      const req = await make({
        name: "getBaseOutputsMetadata",
        data: {
          ...data,
          team_slug: state.item.slug,
          collection_slug: state.collection.slug,
        },
      });
      return req;
    },
    async getBaseOutputsExport({ state }, data) {
      return await make({
        name: "getBaseOutputsExport",
        params: {
          ...data,
          team_slug: state.item.slug,
          collection_slug: state.collection.slug,
        },
        options: {
          responseType: "blob",
        },
      });
    },
    async getCollectionSetsExport({ state }, data) {
      return await make({
        name: "getCollectionSetsExport",
        params: {
          ...data,
          team_slug: state.item.slug,
          collection_slug: state.collection.slug,
        },
        options: {
          responseType: "blob",
        },
      });
    },
    async getBaseSetsExport({ state }, data) {
      return await make({
        name: "getBaseSetsExport",
        params: {
          ...data,
          team_slug: state.item.slug,
          collection_slug: state.collection.slug,
        },
        options: {
          responseType: "blob",
        },
      });
    },
    async deleteDuplicateBaseSets({ state }, data) {
      return await make({
        name: "deleteDuplicateBaseSets",
        data: {
          ...data,
          team_slug: state.item.slug,
          collection_slug: state.collection.slug,
        },
      });
    },
    async getCollectionOutputsMetadata({ state }, data) {
      const req = await make({
        name: "getCollectionOutputsMetadata",
        data: {
          ...data,
          team_slug: state.item.slug,
          collection_slug: state.collection.slug,
        },
      });
      return req;
    },
    async getCollectionOutputs({ state }, data) {
      const req = await make({
        name: "getCollectionOutputs",
        data: {
          ...data,
          team_slug: state.item.slug,
          collection_slug: state.collection.slug,
        },
      });

      return req;
    },
  },
  getters: {
    getBase: (state) => (id) => {
      return state.collection?.bases?.find((b) => b.id == id);
    },
    collectionUrl: (state) => (id) => {
      if (process.env.VUE_APP_API_URL) {
        return `${process.env.VUE_APP_API_URL}/collection/file?collection_slug=${state.collection?.slug}&team_slug=${state.item?.slug}&id=${id}`;
      }
      return `/collection/file?collection_slug=${state.collection?.slug}&team_slug=${state.item?.slug}&id=${id}`;
    },
    outputUrl: (state) => (base_id, id) => {
      if (process.env.VUE_APP_API_URL) {
        return `${process.env.VUE_APP_API_URL}/collection/output?collection_slug=${state.collection?.slug}&team_slug=${state.item?.slug}&base_id=${base_id}&id=${id}`;
      }
      return `/collection/output?collection_slug=${state.collection?.slug}&team_slug=${state.item?.slug}&base_id=${base_id}&id=${id}`;
    },
  },
};

export default team;
